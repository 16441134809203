import T from "T";

export const PROJECT_TYPE = [T.BYT_PROJECT, T.FIXED_COST_PROJECT, T.T_AND_M, T.CSD, T.GIFT];
export const PROJECT_TYPE_MAPPING = [
  { projectTypeName: T.BYT_PROJECT, value: T.BYT },
  { projectTypeName: T.FIXED_COST_PROJECT, value: T.FIXED_PROJECTS },
  { projectTypeName: T.T_AND_M, value: T.TIME_AND_MATERIAL },
  { projectTypeName: T.CSD, value: T.CSD_PROJECT },
  { projectTypeName: T.GIFT, value: T.GIFT },
];
export const PROJECT_STATUS = [T.ACTIVE, T.INACTIVE, T.LOST, T.HOLD, T.COMPLETE];
export const GIFT_STATUS = [
  { name: T.IN_PROGRESS, value: [0, 1, 2, 3] },
  { name: T.APPROVED, value: [4] },
  { name: T.REJECTED, value: [5] },
];
export const CURRENCY_LIST = [T.INR, T.USD, T.CAD, T.AUD];
export const BUSINESS_CENTRAL_FILTER_OPTIONS = [T.STATUS, T.PROJECT_MANAGER, T.VICE_PRESIDENT];
export const GIFT_FILTER_OPTIONS = [T.GIFT_STATUS, T.PROJECTS, T.VICE_PRESIDENT];
export const GIFT_TYPE = [T.BIG, T.MEDIUM, T.SMALL, T.CARD, T.NONE];
export const OCCASION_LIST = [T.FESTIVAL, T.BIRTHDAY, T.OTHER];
export const BUSINESS_CENTRAL_TABLE_HEADER = [
  { label: T.PROJECT_NO },
  { label: T.PROJECT_STATUS },
  { label: T.PROJECT_NAME },
  { label: T.VICE_PRESIDENT },
  { label: T.ACTION_BUTTONS },
];
export const GIFT_TABLE_HEADER = [
  { label: "", key: "checkBox" },
  { label: T.SPOC_DETAILS, key: "spocDetails" },
  { label: T.RECOMMENDATIONS, key: "recommendations" },
  { label: T.PROPOSED_GIFT, key: "proposedGift" },
  { label: T.OCCASION, key: "occasion", align: "center" },
  { label: T.GIFT_TYPE, key: "giftType", align: "center" },
  { label: T.ACTION_BUTTONS, key: "action", align: "center" },
];
export const RESOURSE_TABLE_HEADER = [
  { label: T.RESOURCE_NAME },
  { label: T.START_DATE },
  { label: T.END_DATE },
  { label: T.MONTHLY_SPEND_HOURS },
  { label: T.ACTUAL_SPEND_HOURS },
  // { label: T.RESOURCE_TYPE },
  { label: T.NOTICE_PERIOD },
  { label: T.COST_CLIENT },
  { label: T.INVOICE_DATE },
  { label: T.COMMENTS },
  { label: T.ACTION_BUTTONS },
];
