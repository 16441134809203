import { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
// import { useLazyGetCourseListQuery } from "api/training/courseDetail/getCourseList";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import T from "T";
// import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { handleConfiguratorDragEnd, handleConfigurationChange } from "data/configurator/subCourseSelector";
import MISAutocomplete from "components/common/MISAutocomplete";
import { AddCircle, ExpandMore, RemoveCircle } from "@mui/icons-material";
import MisSubCourseConfigurator from "./MisSubCourseConfigurator";
import usePMFetch from "hooks/usePMFetch";
import { useNavigate, useParams } from "react-router-dom";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISLoader from "components/common/MISLoader";
import { MISCurrentUser } from "utils/validations";
import MISButton from "components/common/MISButton";
import { format, isValid } from "date-fns";
import MISDatePicker from "components/common/MISDatePicker";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useLazyCourseTypesQuery } from "api/training/typeOfCourse/courseType";
import { useAssignCourseMutation } from "api/training/assignCourse/assignUserCourse";
import { toast } from "react-toastify";
import { useGetAssignCourseFilterMutation } from "api/training/assignCourse/filterAssignCourse";
// const { INITIAL_PAGE } = PAGINATION;
// const ROWS_PER_PAGE = 10000;
const emptyCourseData = {
  courseDuration: 0,
  courseName: "",
  trainingCourseId: 0,
  subsCourses: [], //{ subsCourseName: "", training_sub_course_id: 0 }
  configData: [],
  priority: true,
};

const StyledAccordion = styled(Accordion)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const LnDPage = () => {
  const navigate = useNavigate();
  const { routeId, id, userName } = useParams();
  const { courseList, departmentList } = usePMFetch();
  const [getUserList] = useLazyGetUserListQuery();
  const [getCourseType, { isLoading }] = useLazyCourseTypesQuery();
  const [assignCourse] = useAssignCourseMutation();
  const [getFilterAssignCourse] = useGetAssignCourseFilterMutation();

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    trainingList: [
      {
        courseType: "Mandatory",
        allocatedHours: 0,
        courseData: [],
        hoursLeft: 0,
        mentor: {
          userName: "Anubhav Lotey",
          userEmailId: "anubhav.lotey@netsmartz.com",
          id: 1548,
        },
      },
      {
        courseType: "Softskill",
        allocatedHours: 0,
        courseData: [emptyCourseData],
        hoursLeft: 0,
        mentor: {
          userName: "Anubhav Lotey",
          userEmailId: "anubhav.lotey@netsmartz.com",
          id: 1548,
        },
      },
      {
        courseType: "Domain",
        allocatedHours: 0,
        courseData: [emptyCourseData],
        hoursLeft: 0,
        mentor: {
          userName: "",
          userEmailId: "",
          id: 0,
        },
      },
    ],
    usersList: [], // List of user from BE
    courseLists: [], // list of course from BE
    expandedCourseType: "",
    completionDate: null,
    department: null,
    showDomainToNewJoiner: false,
  });

  const { trainingList, usersList, courseLists, expandedCourseType, completionDate, department, showDomainToNewJoiner } =
    localState;

  useEffect(() => {
    getUsersListed();
    getDateAfter11Months();
  }, []);

  useEffect(() => {
    getCourseTypeList();
  }, [courseList]);

  const getUsersListed = () => {
    getUserList({ page: 0, size: 10000, status: T.ACTIVE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({
          usersList: allTableRows,
        });
      })
      .catch(handleError);
  };

  const getCourseTypeList = () => {
    const { allTableRows } = getCurrentTableParams(courseList);

    //change the values of course list and trainlist according to department and because mandatory is prefilled
    //the data is filled here while chaning department

    if (routeId === "2") {
      getFilterAssignCourse({
        page: 0,
        rowPerPage: 1000,
        assignTrainingFilterDto: {
          userId: id,
          isOffline: false,
          trainingType: routeId === "1" ? "Appraisal Linked Learning Path" : "New Joiner Learning Path",
        },
      })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", []);
          let newTrainingList = trainingList?.filter((it) => {
            if (routeId === "2" && !(results[0]?.isNewJoinerLearningPathDateExpired ?? false)) {
              return it.courseType !== "Domain";
            } else {
              return it;
            }
          });

          setLocalState({
            trainingList: newTrainingList,
            showDomainToNewJoiner: results[0]?.isNewJoinerLearningPathDateExpired ?? false,
          });
          getCourseType()
            .unwrap()
            .then((res) => {
              if (res) {
                newTrainingList = newTrainingList.map((it) => ({
                  courseType: it?.courseType,
                  allocatedHours: res.find((i) => i.courseAssignmentType === it?.courseType).duration,
                  courseData:
                    it?.courseType === "Mandatory"
                      ? allTableRows
                          .filter((it) => it?.assignmentType?.courseAssignmentType === "Mandatory")
                          .map((it) => ({
                            courseDuration: it.courseDuration,
                            courseName: it.courseName,
                            trainingCourseId: it.trainingCourseId,
                            subsCourses: it?.subsCourses, //{ subsCourseName: "", training_sub_course_id: 0 }
                            configData:
                              it?.subsCourses.length > 0
                                ? it?.subsCourses.map((subCourse, index) => {
                                    return {
                                      id: subCourse.training_sub_course_id,
                                      position: index,
                                      label: subCourse.subsCourseName,
                                      checked: true,
                                    };
                                  })
                                : [],
                            priority: true,
                          }))
                      : it?.courseData,
                  hoursLeft: it?.hoursLeft,
                  mentor: it?.mentor,
                }));
                setLocalState({
                  courseLists: allTableRows,
                  trainingList: newTrainingList,
                });
              }
            })
            .catch(handleError);
        })
        .catch(handleError);
    } else {
      let newTrainingList = trainingList?.filter((it) => {
        if (routeId === "2" && !showDomainToNewJoiner) {
          return it.courseType !== "Domain";
        } else {
          return it;
        }
      });
      setLocalState({
        trainingList: newTrainingList,
      });

      getCourseType()
        .unwrap()
        .then((res) => {
          if (res) {
            newTrainingList = newTrainingList.map((it) => ({
              courseType: it?.courseType,
              allocatedHours: res.find((i) => i.courseAssignmentType === it?.courseType).duration,
              courseData:
                it?.courseType === "Mandatory"
                  ? allTableRows
                      .filter((it) => it?.assignmentType?.courseAssignmentType === "Mandatory")
                      .map((it) => ({
                        courseDuration: it.courseDuration,
                        courseName: it.courseName,
                        trainingCourseId: it.trainingCourseId,
                        subsCourses: it?.subsCourses, //{ subsCourseName: "", training_sub_course_id: 0 }
                        configData:
                          it?.subsCourses.length > 0
                            ? it?.subsCourses.map((subCourse, index) => {
                                return {
                                  id: subCourse.training_sub_course_id,
                                  position: index,
                                  label: subCourse.subsCourseName,
                                  checked: true,
                                };
                              })
                            : [],
                        priority: true,
                      }))
                  : it?.courseData,
              hoursLeft: it?.hoursLeft,
              mentor: it?.mentor,
            }));
            setLocalState({
              courseLists: allTableRows,
              trainingList: newTrainingList,
            });
          }
        })
        .catch(handleError);
    }
  };

  const handleOnDragEnd = (result, trainingIndex, index) => {
    trainingList[trainingIndex].courseData[index].configData = handleConfiguratorDragEnd({
      result,
      subCourseData: trainingList[trainingIndex].courseData[index].configData,
    });
    setLocalState({
      trainingList,
    });
  };
  const handleConfChange = (ind, type, confData, trainingIndex, index) => {
    if (trainingList[trainingIndex].courseType !== "Mandatory") {
      trainingList[trainingIndex].courseData[index].configData = handleConfigurationChange({
        index: ind,
        type,
        confData,
      });
      setLocalState({
        trainingList,
      });
    }
  };

  const handleCourseRemove = (trainingIndex, index) => {
    const training = trainingList[trainingIndex];
    if (training.courseData[index]) {
      training.courseData.splice(index, 1);
      //check hours
      handleCourseHours(trainingIndex, index);

      setLocalState({ trainingList });
    } else {
      training.courseData.splice(index, 1);
      //check hours
      handleCourseHours(trainingIndex, index);
      setLocalState({ trainingList });
    }
  };

  const handleCourseHours = (trainingIndex, index) => {
    let hoursSelected = 0;
    trainingList[trainingIndex].courseData.forEach((it) => {
      hoursSelected += it?.courseDuration;
    });
    trainingList[trainingIndex].hoursLeft = hoursSelected;
    setLocalState({
      trainingList,
    });
  };

  const handleCourseAdd = (trainingIndex, index) => {
    trainingList[trainingIndex].courseData.push(emptyCourseData);
    setLocalState({ trainingList });
  };

  // ============================Handle The state of Course and subCourse====================
  const handleChangeCourse = (event, newValue, trainingIndex, index) => {
    const selectedCourse = (trainingList[trainingIndex].courseData[index] = {
      courseDuration: get(newValue, "courseDuration", 0),
      courseName: get(newValue, "courseName", 0),
      trainingCourseId: get(newValue, "trainingCourseId", 0),
      subsCourses: get(newValue, "subsCourses", []), //{ subsCourseName: "", training_sub_course_id: 0 }
      configData: [],
      priority: true,
    });
    //course to check hours
    if (selectedCourse) {
      handleCourseHours(trainingIndex, index);
    }

    if (selectedCourse.subsCourses.length > 0) {
      const configData = selectedCourse.subsCourses.map((subCourse, index) => {
        return {
          id: subCourse.training_sub_course_id,
          position: index,
          label: subCourse.subsCourseName,
          checked: true,
        };
      });
      selectedCourse.configData = configData;
    }

    setLocalState({
      trainingList,
    });
  };
  const handleExpand = (courseType) => (event, isExpanded) => {
    setLocalState({
      expandedCourseType: isExpanded ? courseType : NaN,
    });
  };

  const handleDepartmentChange = (event, newValue) => {
    if (newValue) {
      setLocalState({
        department: newValue,
      });
    } else {
      trainingList.forEach((it) => {
        if (it.courseType === "Domain") {
          it.courseData = [emptyCourseData];
          it.hoursLeft = 0;
        }
      });

      setLocalState({
        trainingList,
        department: null,
      });
    }
  };

  const handlePriorityChange = (value, trainingIndex, index) => {
    trainingList[trainingIndex].courseData[index].priority = value;

    setLocalState({
      trainingList,
    });
  };

  const onHandleDateChange = (newValue) => {
    const validDate = new Date(newValue);
    setLocalState({
      completionDate: newValue && isValid(validDate) ? validDate : null,
    });
  };

  const disableSubmit = () => {
    if (!showDomainToNewJoiner) {
      if (department === null && routeId === "1") {
        toast.error("Please select a department");
        return false;
      }
      if (completionDate === null) {
        toast.error("Completion Date is mandatory");
        return false;
      }
      if (trainingList.filter((it) => it.courseType !== "Mandatory").some((it) => !(it.allocatedHours - it.hoursLeft <= 0))) {
        toast.error("Please select the minimum hours of training for each course");
        return false;
      }
      if (trainingList.some((it) => it?.mentor.id === 0)) {
        toast.error("Please select the reviewers for trainings");
        return false;
      }
    } else {
      const newTrainingList = trainingList.filter((it) => it.courseType === "Domain");
      if (department === null) {
        toast.error("Please select a department");
        return false;
      }
      if (completionDate === null) {
        toast.error("Completion Date is mandatory");
        return false;
      }
      if (newTrainingList.filter((it) => it.courseType !== "Mandatory").some((it) => !(it.allocatedHours - it.hoursLeft <= 0))) {
        toast.error("Please select the minimum hours of training for each course");
        return false;
      }
      if (newTrainingList.some((it) => it?.mentor.id === 0)) {
        toast.error("Please select the reviewers for trainings");
        return false;
      }
    }

    // return (
    //   completionDate === null ||
    //   trainingList.filter((it) => it.courseType !== "Mandatory").some((it) => !(it.allocatedHours - it.hoursLeft <= 0)) ||
    //   trainingList.some((it) => it?.mentor.id === 0)
    // );
    return true;
  };

  const handleSubmit = () => {
    if (!disableSubmit()) return;
    let courseDataList = [];

    // Collect all course data from each training type
    for (let i of trainingList) {
      // Map each course data item with the mentor's ID as reviewerId
      const mappedCourses = i.courseData
        .filter((it) => it?.trainingCourseId !== 0)
        .map((course) => ({
          courseId: course.trainingCourseId,
          courseDuration: course.courseDuration,
          subCoursePriorityStatus: course.priority,
          reviewerId: i.mentor.id, // Map the mentor ID from the current training type
          subsCourses: course.subsCourses.map((subCourse) => ({
            subCourseId: subCourse.training_sub_course_id,
          })),
          completionDate: getBEDateFormat(completionDate),
        }));

      // Push the mapped courses to the courseDataList
      courseDataList.push(...mappedCourses);
    }

    // Prepare the final payload
    const payload = {
      userIds: [id],
      trainingType: routeId === "1" ? "Appraisal Linked Learning Path" : "New Joiner Learning Path",
      courseDetailsList: courseDataList,
    };
    assignCourse(payload)
      .unwrap()
      .then((res) => {
        toast.success("Training assigned successfully");
        navigate(-1);
      })
      .catch(handleError);
  };

  const handleChangeMentor = (event, newValue, trainingIndex) => {
    if (newValue) {
      trainingList[trainingIndex].mentor = {
        userName: newValue?.userName,
        userEmailId: newValue?.userEmailId,
        id: newValue?.id,
      };
    } else {
      trainingList[trainingIndex].mentor = {
        userName: "",
        userEmailId: "",
        id: 0,
      };
    }
    setLocalState({
      trainingList,
    });
  };

  const getDateAfter11Months = () => {
    const today = new Date();
    const futureDate = new Date(today);
    // Add 11 months
    futureDate.setMonth(today.getMonth() + 11);

    setLocalState({
      completionDate: isValid(futureDate) ? futureDate : null,
    });
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        height: "calc(100vh - 130px)",
        flexDirection: "column",
      }}
    >
      <Grid xs={12} fontSize={24} padding={2} alignSelf={"flex-start"}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        {routeId === "1"
          ? `Assign Appraisal Linked Learning Path ${T.TO} ${userName}`
          : `Assign New Joiners Learning Path ${T.TO} ${userName} `}
      </Grid>
      <Grid container spacing={2} padding={2} sx={{ overflowY: "auto" }}>
        <Grid item xs={4}>
          <MISDatePicker
            label={T.COMPLETION_DATE}
            placeholder={T.COMPLETION_DATE}
            inputFormat="MM/DD/YYYY"
            value={completionDate}
            name="completionDate"
            required
            handleChange={(event) => onHandleDateChange(event)}
          />
        </Grid>
        <Grid container item spacing={2}>
          {trainingList.map((training, trainingIndex) => {
            return (
              <Grid container item key={trainingIndex}>
                <StyledAccordion
                  disabled={routeId === "2" && showDomainToNewJoiner && training?.courseType !== "Domain"}
                  style={{ width: "100vw" }}
                  variant="outlined"
                  expanded={training?.courseType === expandedCourseType}
                  onChange={handleExpand(training?.courseType)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container item>
                      <Grid item xs={4}>
                        {(() => {
                          let displayText;
                          switch (training?.courseType) {
                            case "Softskill":
                              displayText = "Softskills Training";
                              break;
                            case "Domain":
                              displayText = "Domain Related Training";
                              break;
                            case "Mandatory":
                              displayText = "Organizational Trainings";
                              break;
                            default:
                              displayText = training?.courseType;
                          }
                          return <h4>{displayText}</h4>;
                        })()}
                      </Grid>

                      <Grid item xs={4} sx={{ textAlign: "start" }}>
                        <h5>{training?.mentor?.userName && `Reviewer:- ${training?.mentor?.userName}`}</h5>
                      </Grid>
                      {
                        <Grid item xs={4} sx={{ textAlign: "end", paddingX: 2 }}>
                          <h5>
                            {`Total:- ${training?.courseData.reduce(function (acc, obj) {
                              return acc + obj.courseDuration;
                            }, 0)}h`}
                          </h5>
                        </Grid>
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {training?.courseType !== "Mandatory" && training?.courseType !== "Domain" && (
                      <Grid container justifyContent={"space-between"} item xs={12}>
                        <Grid item xs={12} sx={{ textAlign: "end", paddingX: 2, marginBottom: 2 }}>
                          <h5>
                            {training?.allocatedHours - training?.hoursLeft >= 0
                              ? `${training?.allocatedHours - training?.hoursLeft} hours left`
                              : "0 hours left"}
                          </h5>
                        </Grid>
                      </Grid>
                    )}
                    {training?.courseType === "Domain" ? (
                      <Grid container item xs={12} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <MISAutocomplete
                                label="Please filter out the trainings by choosing the Department from here"
                                listDetails={get(departmentList, "results", [])}
                                getByLabelText={(option) => get(option, "departmentName", "")}
                                value={department}
                                sx={{
                                  ".MuiAutocomplete-popper": { zIndex: 1100 },
                                }}
                                onHandleChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                              <h5>
                                {training?.allocatedHours - training?.hoursLeft >= 0
                                  ? `${training?.allocatedHours - training?.hoursLeft} hours left`
                                  : "0 hours left"}
                              </h5>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    {training?.courseData?.map((it, index) => (
                      <div key={index}>
                        {training?.courseType === "Mandatory" ? (
                          <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid>{it?.courseName}</Grid>

                            {it?.subsCourses.length > 0 && (
                              <Grid item xs={12} mt={2}>
                                <Grid item xs={12}>
                                  <Box>
                                    <FormControl component="fieldset">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={it?.priority}
                                            onChange={(e) => {
                                              handlePriorityChange(e.target.checked, trainingIndex, index);
                                            }}
                                            name="priority"
                                            sx={{
                                              paddingLeft: 3,
                                            }}
                                          />
                                        }
                                        label={"Priority"}
                                      />
                                    </FormControl>
                                    <Typography ml={1}>{T.COURSE_NAME}</Typography>
                                    <MisSubCourseConfigurator
                                      configData={it?.configData}
                                      handleOnDragEnd={(result) => handleOnDragEnd(result, trainingIndex, index)}
                                      handleConfChange={(ind, type, confData) =>
                                        handleConfChange(ind, type, confData, trainingIndex, index)
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Paper>
                        ) : (
                          <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid container item>
                              <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item xs={6}>
                                  <MISAutocomplete
                                    label={T.COURSE}
                                    getByLabelText={(option) => get(option, "courseName", "")}
                                    sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
                                    listDetails={
                                      training.courseType !== "Domain"
                                        ? courseLists.filter(
                                            (assignment) =>
                                              assignment.assignmentType.courseAssignmentType === training.courseType &&
                                              !training.courseData.some(
                                                (it) => it.trainingCourseId === assignment?.trainingCourseId,
                                              ),
                                          )
                                        : courseLists.filter(
                                            (assignment) =>
                                              assignment.department.id === parseInt(department?.id) &&
                                              assignment.assignmentType.courseAssignmentType === training.courseType &&
                                              !training.courseData.some(
                                                (it) => it.trainingCourseId === assignment?.trainingCourseId,
                                              ),
                                          )
                                    }
                                    value={trainingList[trainingIndex].courseData[index]}
                                    onHandleChange={(event, newValue) => {
                                      handleChangeCourse(event, newValue, trainingIndex, index);
                                    }}
                                  />
                                </Grid>
                                <Grid>
                                  <h5>{`Duration:- ${trainingList[trainingIndex].courseData[index]?.courseDuration} hour`}</h5>
                                </Grid>
                                <Grid item>
                                  {trainingList[trainingIndex].courseData.length - 1 === index && (
                                    <IconButton
                                      disabled={
                                        trainingList[trainingIndex].allocatedHours - trainingList[trainingIndex].hoursLeft <= 0
                                      }
                                      onClick={() => {
                                        handleCourseAdd(trainingIndex, index);
                                      }}
                                    >
                                      <AddCircle />
                                    </IconButton>
                                  )}

                                  {trainingList[trainingIndex].courseData.length > 1 && (
                                    <IconButton
                                      onClick={() => {
                                        handleCourseRemove(trainingIndex, index);
                                      }}
                                    >
                                      <RemoveCircle />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid item xs={12} mt={2}>
                                {it?.subsCourses.length > 0 && (
                                  <Grid item xs={12}>
                                    <Box>
                                      <FormControl component="fieldset">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={it?.priority}
                                              onChange={(e) => {
                                                handlePriorityChange(e.target.checked, trainingIndex, index);
                                              }}
                                              name="priority"
                                              sx={{
                                                paddingLeft: 3,
                                              }}
                                            />
                                          }
                                          label={"Priority"}
                                        />
                                      </FormControl>
                                      <Typography ml={1}>{T.COURSE_NAME}</Typography>
                                      <MisSubCourseConfigurator
                                        configData={it?.configData}
                                        handleOnDragEnd={(result) => handleOnDragEnd(result, trainingIndex, index)}
                                        handleConfChange={(ind, type, confData) =>
                                          handleConfChange(ind, type, confData, trainingIndex, index)
                                        }
                                      />
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                      </div>
                    ))}
                    <Grid item xs={4} mt={2}>
                      <MISAutocomplete
                        label={T.REVIEWER_NAME}
                        getByLabelText={(option) => get(option, "userEmailId", "")}
                        sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
                        listDetails={usersList}
                        value={trainingList[trainingIndex].mentor}
                        onHandleChange={(event, newValue) => {
                          handleChangeMentor(event, newValue, trainingIndex);
                        }}
                        required
                      />
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid sx={{ p: 2, alignSelf: "flex-end", mt: "auto" }}>
        <Button
          variant="outlined"
          size={"small"}
          sx={{
            borderColor: BACKGROUND.black,
            color: BACKGROUND.black,

            "&:hover": {
              borderColor: NETSMARTZ_THEME_COLOR,
              color: NETSMARTZ_THEME_COLOR,
            },
            mr: 2,
          }}
          onClick={() => navigate(-1)}
        >
          {"Cancel"}
        </Button>
        <MISButton onClick={handleSubmit}>Submit</MISButton>
      </Grid>
    </Paper>
  );
};

LnDPage.propTypes = {
  isDialogOpen: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleAssignedCourseDialog: PropTypes.func,
  selectedUserName: PropTypes.string,
  swotId: PropTypes.number,
  refreshPage: PropTypes.func,
};

export default LnDPage;
